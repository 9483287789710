import { styled } from 'utils/emotion';

/**
 * Field wrapper.
 */
export const FormFieldWrapperStyled = styled('div')({
  position: 'relative',
  display: 'block',
  margin: '0 0 20px',
});

/**
 * Field inputs.
 */
const FormFieldStyles = {
  width: '100%',
  color: 'rgba(0,0,0,1)',
  display: 'block',
  background: 'transparent',
  borderRadius: 0,
  border: 0,
  borderBottom: '1px solid rgba(0,0,0,1)',
  fontSize: '16px',
  padding: '20px 0',
  margin: '0 0 10px',

  '&:focus': {
    outline: 0,
  },

  // Placeholder
  '::-webkit-input-placeholder': {
    color: 'rgba(0,0,0,.7)',
  },
  '::-moz-placeholder': {
    color: 'rgba(0,0,0,.7)',
  },
  ':-ms-input-placeholder': {
    color: 'rgba(0,0,0,.7)',
  },
  ':-moz-placeholder': {
    color: 'rgba(0,0,0,.7)',
  },

  // Autofill
  '&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover, &:-webkit-autofill:active': {
    WebkitTransitionDelay: '99999s',
  },
};

export const FormFieldTextStyled = styled('input')(FormFieldStyles);

export const FormFieldCheckboxStyled = styled('input')({
  position: 'absolute',
  top: 6,
  left: 3,
  opacity: 0,
});

export const FormFieldTextareaStyled = styled('textarea')(FormFieldStyles, {
  height: 120,
  resize: 'vertical',
});

/**
 * Field Labels.
 */
export const FormFieldLabelStyled = styled('label')({
  display: 'block',
  width: '100%',
  fontSize: '.8em',
  lineHeight: '1.2',
});

export const FormFieldCheckboxLabelStyled = styled('label')(
  {
    textAlign: 'left',
    display: 'flex',
    flexWrap: 'nowrap',
    userSelect: 'none',
    lineHeight: '1.5',

    p: {
      margin: 0,
    },

    // Pseudo checkbox
    '&::before': {
      flex: 'none',
      content: '""',
      width: '1em',
      height: '1em',
      border: '1px solid black',
      boxSizing: 'border-box',
      boxShadow: 'inset 0px 0px 0px 3px rgba(255,255,255,1)',
      display: 'block',
      borderRadius: 5,
      marginTop: 4,
      marginRight: 10,
    },

    // Checked pseudo checkbox
    'input[type="checkbox"]:checked + &::before': {},

    // Links in checkbox label
    a: {
      color: 'inherit',
      transition: 'opacity .15s',
    },

    'a:hover': {
      opacity: 0.8,
    },
  },
  props => ({
    // Checked pseudo checkbox
    'input[type="checkbox"]:checked + &::before': {
      backgroundColor: props.theme.primaryColor,
    },
  }),
);

/**
 * Field Messages.
 */
export const MessageErrorStyled = styled('p')(
  {
    fontSize: '.8em',
  },
  ({ theme }) => ({
    color: theme.colorError,
  }),
);
