import React from 'react';
import { styled, css, cx } from 'utils/emotion';
import theme from 'components/Layout/themeDefault';
import Link from 'components/Link';

const buttonBasic = css({
  width: 'auto',
  padding: theme.button.padding,
  borderRadius: theme.borderRadius,
  border: '1px solid transparent',
  display: 'inline-block',
  textDecoration: 'none',
  fontWeight: theme.button.fontWeight,
  fontSize: theme.button.fontSize,
  lineHeight: 1,
  '&:hover, &:focus': {
    cursor: 'pointer',
  },
});

export const buttonPrimary = css(buttonBasic, {
  backgroundColor: theme.button.primaryBgColor,
  color: theme.button.primaryTextColor,
  transition: theme.button.transition,
  '&:hover, &:focus': {
    color: theme.button.primaryTextColor,
  },
});

export const buttonSecondary = css(buttonBasic, {
  backgroundColor: theme.button.secondaryBgColor,
  color: theme.button.secondaryTextColor,
  transition: theme.button.transition,
  '&:hover, &:focus': {
    color: theme.button.secondaryTextColor,
  },
});

export const buttonTertiary = css(buttonBasic, {
  border: '1px solid white',
  backgroundColor: theme.button.tertiaryBgColor,
  color: theme.button.tertiaryTextColor,
  transition: theme.button.transition,
  '&:hover, &:focus': {
    color: theme.button.tertiaryTextColor,
  },
});

export const buttonSm = css({
  padding: theme.button.paddingSm,
});

const StyledButton = styled(Link)(
  {
    textDecoration: 'none!important',
  },
  props =>
    props.fullSize && {
      width: '100%!important',
      whiteSpace: 'normal!important',
    },
  props =>
    props.textAlign && {
      textAlign: 'center',
    },
);

export default function Button({
  children,
  className,
  to,
  fullSize,
  textAlign,
}) {
  return (
    <StyledButton
      to={to}
      fullSize={fullSize}
      textAlign={textAlign}
      className={cx(buttonPrimary, className)}
    >
      {children}
    </StyledButton>
  );
}
