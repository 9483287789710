import React from 'react';
import { styled } from 'utils/emotion';
import PropTypes from 'prop-types';
import NullWrap from 'components/NullWrap';

const StyledWrapImage = styled('div')(
  {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  props => ({
    position: props.position ? props.position : 'relative',
    top: props.top,
    left: props.left,
    paddingTop: props.aspectRatio,
    margin: props.margin,
    boxShadow:
      props.boxShadow || (props.hasBoxShadow && props.theme.imageBoxShadow),
    '&:after': {
      display: props.hasStripe ? 'block' : 'none',
      content: '""',
      position: 'absolute',
      top: '100%',
      left: props.stripePosition === 'left' && '0',
      right: props.stripePosition === 'right' && '0',
      height: '8px',
      width: '50%',
      background: props.theme.backgroundImageStripe,
    },
  }),
);

const StyledBackground = styled('div')(
  {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
  },
  props => ({
    position: props.position ? props.position : 'absolute',
    backgroundImage: `url(${props.src})`,
    backgroundSize: props.fit,
    backgroundPosition: props.backgroundPosition,
  }),
);

const StyledImage = styled('img')(
  {
    left: 0,
    top: '50%',
    width: '100%',
    height: 'auto',
    transform: 'translateY(-50%)',
    verticalAlign: 'bottom',
  },
  props => ({
    position: props.position ? props.position : 'absolute',

    // Temporary fix for IE11's inability to use object-fit.
    // Only use it if it's supported, otherwise use fallback with oldschool positioning.
    '@supports(object-fit: cover)': {
      objectFit: props.fit,
      height: '100%',
      transform: 'none',
      top: 0,
    },
  }),
);

const StyledImageRaw = styled('img')(
  {
    maxWidth: '100%',
    verticalAlign: 'bottom',
  },
  props => ({
    objectFit: props.fit,
  }),
);

export default function Image(props) {
  const {
    src,
    alt,
    fit,
    isBackground,
    backgroundPosition,
    aspectRatio,
    position,
    top,
    left,
    hasBoxShadow,
    hasStripe,
    margin,
    stripePosition,
    className,
  } = props;

  const aspectRatios = {
    '0': '0', // no aspect ratio
    '1': '100%', // squared
    '1:2': '50%', // half height
    '1:3': '33.33333%', // third height
    '1:4': '25%',
    '16:9': '56.25%',
    '4:3': '75%',
    '3:2': '66.66666%',
    '8:5': '62.5%',
    '9:16': '177.77777%',
  };

  const ComponentWrap =
    aspectRatio === '0' ? NullWrap : isBackground ? NullWrap : StyledWrapImage;

  const ComponentImage =
    aspectRatio !== '0' || isBackground
      ? isBackground
        ? StyledBackground
        : StyledImage
      : StyledImageRaw;

  return (
    <ComponentWrap
      aspectRatio={aspectRatios[aspectRatio]}
      hasStripe={hasStripe}
      hasBoxShadow={hasBoxShadow}
      margin={margin}
      stripePosition={stripePosition}
      position={position}
      top={top}
      left={left}
      className={className}
    >
      <ComponentImage
        src={src}
        alt={alt}
        fit={fit}
        position={position}
        backgroundPosition={backgroundPosition}
      />
    </ComponentWrap>
  );
}

Image.propTypes = {
  aspectRatio: PropTypes.string,
  isBackground: PropTypes.bool,
  backgroundPosition: PropTypes.string,
  fit: PropTypes.string,
  hasStripe: PropTypes.bool,
  hasBoxShadow: PropTypes.bool,
};

Image.defaultProps = {
  aspectRatio: '0',
  isBackground: false,
  backgroundPosition: 'center',
  fit: 'cover',
  hasStripe: false,
  hasBoxShadow: false,
};
