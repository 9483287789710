import React from 'react';
import { graphql } from 'gatsby';
import NewsLatest from 'components/NewsLatest';
import NewsArchive from 'components/NewsArchive';
import JobsLatest from 'components/JobsLatest';
import Locations from 'components/Locations';
import Memberships from 'components/Memberships';

export default function Module({ pageBuilder }) {
  const { headline, image, moduleText, moduleName } = pageBuilder;

  switch (moduleName) {
    case 'locations':
      return <Locations headline={headline} text={moduleText?.content} />;
    case 'newslatest':
      return (
        <NewsLatest
          headline={headline}
          text={moduleText?.content}
          image={image}
        />
      );
    case 'newsarchive':
      return (
        <NewsArchive
          headline={headline}
          text={moduleText?.content}
          image={image}
        />
      );
    case 'jobslatest':
      return (
        <JobsLatest
          headline={headline}
          text={moduleText?.content}
          image={image}
        />
      );
    case 'memberships':
      return <Memberships headline={headline} text={moduleText?.content} />;
    default:
      return null;
  }
}

export const query = graphql`
  fragment PageBuilderModuleQuery on Craft_PageBuilderModule {
    id
    headline
    image {
      url
      full: url(crop: { width: 1920, quality: 90 })
    }
    moduleName
    moduleText {
      content
    }
  }
`;
