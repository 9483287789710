import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'utils/emotion';

// Hotfix for https://github.com/gatsbyjs/gatsby/issues/9489 until a newer react version is out
import { setConfig } from 'react-hot-loader';
setConfig({ pureSFC: true });

import media from 'utils/mediaqueries';
import { toPercent } from 'utils/math';

import { GridContext } from 'components/Grid';

const getStyledGridItem = (as = 'div') =>
  styled(as)(
    {
      flex: '0 0 auto',
      msFlex: '0 0 auto',
    },
    props => ({
      margin: props.margin,
      alignSelf: props.alignSelf,
      background: props.bg,
      height: props.height,
      maxWidth: (props.xxs === '0%' && '100%') || props.xxs,
      flexBasis: (props.xxs === '0%' && 'auto') || props.xxs,
      [media('xs')]: {
        maxWidth: (props.xs === '0%' && '100%') || props.xs,
        flexBasis: (props.xs === '0%' && 'auto') || props.xs,
      },
      [media('sm')]: {
        maxWidth: (props.sm === '0%' && '100%') || props.sm,
        flexBasis: (props.sm === '0%' && 'auto') || props.sm,
      },
      [media('md')]: {
        maxWidth: (props.md === '0%' && '100%') || props.md,
        flexBasis: (props.md === '0%' && 'auto') || props.md,
      },
      [media('lg')]: {
        maxWidth: (props.lg === '0%' && '100%') || props.lg,
        flexBasis: (props.lg === '0%' && 'auto') || props.lg,
      },
      [media('xl')]: {
        maxWidth: (props.xl === '0%' && '100%') || props.xl,
        flexBasis: (props.xl === '0%' && 'auto') || props.xl,
      },
      padding: `0 ${props.gutter / 2}px`,
      order: props.order,
    }),
  );

export default function GridItem(props) {
  const {
    children,
    height,
    first,
    last,
    xxs,
    xs,
    sm,
    md,
    lg,
    xl,
    top,
    middle,
    bottom,
    as,
    bg,
    margin,
    ...rest
  } = props;

  const StyledGridItem = getStyledGridItem(as);

  const order = {
    first: '1',
    last: '-1',
  };

  const alignself = {
    top: 'flex-start',
    middle: 'center',
    bottom: 'flex-end',
  };

  const percentXxs = toPercent(xxs);
  const percentXs = toPercent(xs);
  const percentSm = toPercent(sm);
  const percentMd = toPercent(md);
  const percentLg = toPercent(lg);
  const percentXl = toPercent(xl);

  return (
    <GridContext.Consumer>
      {({ gutter }) => (
        <StyledGridItem
          alignself={
            alignself[
              (top && 'top') || (middle && 'middle') || (bottom && 'bottom')
            ]
          }
          gutter={gutter}
          height={height}
          order={order[(first && 'first') || (last && 'last')]}
          xxs={percentXxs}
          xs={percentXs}
          sm={percentSm}
          md={percentMd}
          lg={percentLg}
          xl={percentXl}
          margin={margin}
          {...rest}
        >
          {children}
        </StyledGridItem>
      )}
    </GridContext.Consumer>
  );
}

GridItem.propTypes = {
  mobile: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  margin: PropTypes.string,
};

GridItem.defaultProps = {
  mobile: 1,
  first: false,
  last: false,
  as: 'div',
  margin: '',
};
