import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Headline from 'components/Headline';
import Image from 'components/Image';
import { BLACK_TRANS_GRADIENT, BLUE_GRADIENT } from 'components/Gradients';

export const NewsSectionStyled = styled('section')({
  position: 'relative',
  boxSizing: 'border-box',
  background: BLUE_GRADIENT,
  padding: '40px 0 20px',

  [media('sm')]: {
    padding: '80px 0',
  },
});

export const ImageWrapperStyled = styled('div')({
  position: 'absolute',
  top: 0,
  width: '100%',

  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background: BLACK_TRANS_GRADIENT,
  },
});

export const ImageStyled = styled(Image)({
  position: 'relative',
  top: 0,
  left: 0,
  paddingTop: '33.33%',

  [media('md')]: {
    paddingTop: '480px',
  },
});

export const WrapperStyled = styled('div')({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  padding: '20px',

  [media('sm')]: {
    padding: '20px 40px',
    flexDirection: 'row',
  },

  [media('lg')]: {
    padding: 0,
  },
});

/**
 * Navigation.
 */
export const NavigationWrapperStyled = styled('nav')(
  {
    position: 'relative',
    minWidth: 120,
    maxWidth: '100%',
    boxShadow: '0 3px 10px rgba(0,0,0,.16)',
    backgroundColor: 'white',
    margin: '0 0 20px 0',

    [media(0, 'xs')]: {
      '&::before, &::after': {
        pointerEvents: 'none',
        position: 'absolute',
        content: '""',
        top: 0,
        width: 20,
        zIndex: 1,
        height: '100%',
        borderRadius: 'inherit',
      },
    },

    [media('sm')]: {
      alignSelf: 'flex-start',
      margin: '0 50px 0 0',
    },

    [media('md')]: {
      minWidth: 160,
    },

    '&::before': {
      left: 0,
      background:
        'linear-gradient(to left, rgba(255,255,255,0) 0%, white 100%)',
    },
    '&::after': {
      right: 0,
      background:
        'linear-gradient(to right, rgba(255,255,255,0) 0%, white 100%)',
    },
  },
  props => ({
    borderRadius: props.theme.borderRadius,
  }),
);

// Scroll container
export const NavigationStyled = styled('ul')(
  {
    position: 'relative',
    display: 'block',

    [media(0, 'xs')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      width: '100%',
      WebkitOverflowScrolling: 'touch',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  props => ({
    borderRadius: props.theme.borderRadius,
  }),
);

export const NavigationItemStyled = styled('li')(
  {
    flex: '0 0 auto',
    position: 'relative',
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRight: '1px solid rgba(42, 106, 250, .1)',

    '&:last-child': {
      borderRight: 0,
      borderBottom: 0,
    },

    [media('sm')]: {
      display: 'block',
      padding: '20px 40px',
      borderRight: 0,
      borderBottom: '1px solid rgba(42, 106, 250, .1)',
    },
  },
  props => ({
    color: props.theme.primaryColor,
  }),
  props =>
    props.active && {
      fontWeight: 'bold',

      // Indicator arrow for nav item
      [media('sm')]: {
        '&::after': {
          position: 'absolute',
          content: '""',
          width: 0,
          height: 0,
          right: -50,
          borderTop: '8px solid transparent',
          borderBottom: '8px solid transparent',
          borderRight: '8px solid white',
        },
      },
    },
);

/**
 * Content.
 */

export const HeadlineWrapperStyled = styled('div')({
  textAlign: 'center',
  padding: '0 40px',

  [media('md')]: {
    textAlign: 'left',
    margin: '40px 0 60px',
  },
});

export const HeadlineStyled = styled(Headline)({
  color: 'white',
});

export const ContentStyled = styled('div')(
  {
    boxShadow: '0 3px 10px rgba(0,0,0,.16)',
    backgroundColor: 'white',
    width: '100%',
  },
  props => ({
    borderRadius: props.theme.borderRadius,
  }),
);

export const NoEntriesStyled = styled('p')({
  padding: '10px 20px',

  [media('sm')]: {
    padding: '20px 40px',
  },
});
