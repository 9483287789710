import React, { Component } from 'react';

import { css, styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Redactor from 'components/Redactor';

import Headline from 'components/Headline';
import Container from 'components/Container';
import Section from 'components/Section';
import { bgGradientOverlayBlack } from 'components/Gradients';

const heroSection = css({
  backgroundSize: 'cover',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  zIndex: '0',
  padding: '60px 20px 120px',
  minHeight: 400,
  [media('md')]: {
    minHeight: 480,
  },
});

const HeroContent = styled('div')({
  maxWidth: 780,
  textAlign: 'center',
  color: 'white',
  padding: '30px 0',
  margin: '0 auto',
  a: {
    marginTop: 40,
  },
});

export default class Hero extends Component {
  render() {
    const { headline, text, backgroundImage } = this?.props;
    const bgImage = backgroundImage[0]
      ? `url(' ${backgroundImage[0].full} ')`
      : 'none';

    const styles = {
      backgroundImage: `${bgImage}`,
      backgroundPosition: '50% 0%',
    };

    return (
      <Section
        id={this?.props?.id}
        style={styles}
        className={`${bgGradientOverlayBlack} ${heroSection}`}
      >
        <Container>
          <HeroContent>
            {headline && (
              <Headline tag="h3" brush={{ color: 'white' }}>
                {headline}
              </Headline>
            )}
            {text && <Redactor content={text} />}
          </HeroContent>
        </Container>
      </Section>
    );
  }
}
