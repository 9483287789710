import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Section from 'components/Section';
import Container from 'components/Container';
import Headline from 'components/Headline';
import Redactor from 'components/Redactor';
import { bgGradientBlue } from 'components/Gradients';

import Map from './Map';
import Card from './Card';
import List from './List';

const LocationsSection = styled(Section)(
  {
    overflow: 'hidden',
    minHeight: '80vh',

    [media('md')]: {
      minHeight: '100vh',
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.primaryColor,
    [media('sm')]: bgGradientBlue,
  }),
);

const LocationsWrapper = styled('div')({
  position: 'relative',
  color: 'white',
  textAlign: 'center',
  padding: '0px 15px',

  '.text': {
    display: 'none',
  },

  [media('sm')]: {
    width: '55%',
    textAlign: 'left',

    '.text': {
      display: 'block',
    },
  },
});

const LocationsHeader = styled('div')({
  [media('sm')]: {
    width: '80%',
  },
});

class Locations extends Component {
  state = {
    isTypeOffices: false,
    isTypeCourts: true,
    activeLocation: null,
  };

  /**
   * Event:
   * Handle toggle button click.
   */
  handleToggleButtonClick = () => {
    const { isTypeOffices, isTypeCourts } = this.state;

    this.setState({
      isTypeOffices: !isTypeOffices,
      isTypeCourts: !isTypeCourts,
      activeLocation: null,
    });
  };

  /**
   * Event:
   * Handle toggle option 'courts' click.
   */
  handleToggleOptionCourtsClick = () => {
    this.setState({
      isTypeOffices: false,
      isTypeCourts: true,
      activeLocation: null,
    });
  };

  /**
   * Event:
   * Handle toggle option 'offices' click.
   */
  handleToggleOptionOfficesClick = () => {
    this.setState({
      isTypeOffices: true,
      isTypeCourts: false,
      activeLocation: null,
    });
  };

  /**
   * Event:
   * Change active location.
   * Either via list item or map dot.
   */
  handleLocationChange = location => {
    const { activeLocation } = this.state;
    let nextActiveLocation = location;

    // Check if location is already active.
    if (activeLocation === location) {
      nextActiveLocation = null;
    }

    this.setState({
      activeLocation: nextActiveLocation,
    });
  };

  /**
   * Event:
   * Reset active location.
   */
  handleLocationReset = () => {
    this.setState({
      activeLocation: null,
    });
  };

  /**
   * Function:
   * Get loations by type.
   */
  getLocationsByType = type => {
    const { items } = this.props;

    return items.filter(
      location => location.locationType[0].locationTypeId === type,
    );
  };

  /**
   * Render.
   */
  render() {
    const { headline, text } = this.props;
    const { isTypeOffices, isTypeCourts, activeLocation } = this.state;

    const type = isTypeOffices ? 'office' : 'court';
    const filteredItems = this.getLocationsByType(type);

    return (
      <LocationsSection>
        <Container>
          <Map
            items={filteredItems}
            activeLocation={activeLocation}
            isTypeOffices={isTypeOffices}
            isTypeCourts={isTypeCourts}
            onMapItemClick={this.handleLocationChange}
          />
          <LocationsWrapper>
            <LocationsHeader>
              {headline && (
                <Headline
                  className={headline}
                  tag="h2"
                  brush={{ color: 'white' }}
                >
                  {headline}
                </Headline>
              )}
              {text && (
                <div className="text">
                  <Redactor content={text} />
                </div>
              )}
            </LocationsHeader>
            {filteredItems.length > 0 && (
              <List
                items={filteredItems}
                activeLocation={activeLocation}
                isTypeOffices={isTypeOffices}
                isTypeCourts={isTypeCourts}
                onListItemClick={this.handleLocationChange}
                onToggleOptionOfficesClick={this.handleToggleOptionOfficesClick}
                onToggleOptionCourtsClick={this.handleToggleOptionCourtsClick}
                onToggleButtonClick={this.handleToggleButtonClick}
              />
            )}
          </LocationsWrapper>

          {activeLocation && (
            <Card
              onCloseButtonClick={this.handleLocationReset}
              {...activeLocation}
            />
          )}
        </Container>
      </LocationsSection>
    );
  }
}

// export default Locations;

export default props => (
  <StaticQuery
    query={graphql`
      fragment LocationFieldsFragment on Craft_Locations {
        id
        title
        slug
        locationAddress
        locationZip
        locationCity
        locationPhone
        locationFax
        locationEmail
        locationType {
          id
          slug
          title
          locationTypeId
        }
        locationPositionX
        locationPositionY
      }

      query LocationsQuery {
        craft {
          entries(section: locations, orderBy: "title ASC") {
            ... on Craft_Locations {
              ...LocationFieldsFragment

              locationRelated {
                ... on Craft_Locations {
                  ...LocationFieldsFragment
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Locations
        items={data.craft.entries}
        types={data.craft.categories}
        {...props}
      />
    )}
  />
);
