import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { withTheme } from 'emotion-theming';

import { parseAsText } from 'utils/parser';
import { css, styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Container from 'components/Container';
import Section from 'components/Section';
import Headline from 'components/Headline';
import TextDefault from 'components/Text';
import Redactor from 'components/Redactor';
import Button from 'components/Button';

const textBlockSection = css({
  backgroundColor: 'white',
  textAlign: 'center',
  [media(640)]: {
    textAlign: 'left',
  },
});

const TextBlock = styled('div')(
  {
    display: 'block',
    padding: '0 0 20px',
    textAlign: 'left',
  },
  props => ({
    [media(960)]: {
      columnGap: '75px',
      columns: props.cols,
    },
  }),
);

const TextWrap = styled('div')({
  '&.headlineLeft': {
    [media('md')]: {
      display: 'flex',
      '.headline': {
        maxWidth: '380px',
        paddingBottom: '0',
      },
      '.text': {
        flex: '1',
        paddingLeft: '100px',
      },
    },
  },
});

const LinkButtonStyled = styled(Button)({
  marginTop: 20,
  [media(0, 'xs')]: {
    whiteSpace: 'normal',
    lineHeight: '1.5',
  },
});

class Text extends Component {
  render() {
    const {
      textRedactor,
      headline,
      headlineTag,
      headlinePosition,
      textColumns,
      textLinkButtonTitle,
      textLinkButtonUrl,
    } = this?.props?.pageBuilder;
    const { theme } = this.props;
    const cssColumns = `auto ${
      textColumns ? textColumns.replace('col', '') : '1'
    }`;

    return (
      <Section id={this?.props?.id} className={textBlockSection}>
        <Container>
          <TextWrap
            className={headlinePosition === 'left' ? 'headlineLeft' : ''}
          >
            {headline && (
              <Headline
                className="headline"
                tag={headlineTag}
                brush={{ color: theme.primaryColor }}
              >
                {parseAsText(headline)}
              </Headline>
            )}
            {textRedactor && (
              <TextDefault tag="div" className="text">
                <TextBlock cols={cssColumns}>
                  <>
                    {textRedactor?.content && (
                      <Redactor content={textRedactor.content} />
                    )}
                  </>
                </TextBlock>

                {textLinkButtonTitle && (
                  <LinkButtonStyled to={textLinkButtonUrl}>
                    {textLinkButtonTitle}
                  </LinkButtonStyled>
                )}
              </TextDefault>
            )}
          </TextWrap>
        </Container>
      </Section>
    );
  }
}

export default withTheme(Text);

export const query = graphql`
  fragment PageBuilderTextQuery on Craft_PageBuilderText {
    id
    textRedactor {
      content
    }
    headline
    headlineTag
    headlinePosition
    textColumns
    textLinkButtonTitle
    textLinkButtonUrl
  }
`;
