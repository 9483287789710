import React from 'react';
import Moment from 'react-moment';
import { parseAsText } from 'utils/parser';
import Link from 'components/Link';
import IconDownload from 'components/Svg/IconDownload';
import {
  DateStyled,
  TileStyled,
  TitleStyled,
  IntroStyled,
  DownloadStyled,
  DownloadLinkStyled,
} from './styles';

function Tile({ entry }) {
  return (
    <TileStyled>
      <div>
        <DateStyled>
          <Moment format="DD.MM.YYYY" unix>
            {entry.postDate}
          </Moment>{' '}
        </DateStyled>
        <TitleStyled>
          {entry.fullUri && (
            <Link to={entry.fullUri}>{parseAsText(entry.title)}</Link>
          )}
        </TitleStyled>
        {entry.newsIntro && (
          <IntroStyled>
            {parseAsText(`${entry.newsIntro.substring(0, 80)} ...`)}
          </IntroStyled>
        )}
      </div>
      {entry.newsDownload.length > 0 ? (
        entry.newsDownload.map(download => (
          <DownloadStyled key={download.id} available>
            <DownloadLinkStyled
              to={download.url}
              title={download.title}
              download
            >
              <IconDownload width="20px" height="24px" />
            </DownloadLinkStyled>
          </DownloadStyled>
        ))
      ) : (
        <DownloadStyled>
          <IconDownload width="20px" height="24px" />
        </DownloadStyled>
      )}
    </TileStyled>
  );
}

export default Tile;
