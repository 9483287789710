import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Moment from 'react-moment';

import { parseAsText } from 'utils/parser';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import ReadMoreLink from 'components/ReadMoreLink';

const Box = styled('div')({
  backgroundColor: '#fff',
  maxWidth: '100%',
  padding: '30px 25px 30px',
  borderRadius: '5px',
  boxShadow: '0 3px 10px rgba(0,0,0,0.16)',
  height: 'auto',
  overflow: 'hidden',
  marginBottom: 40,
  paddingBottom: 40,

  [media('sm')]: {
    height: 400,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  [media('md')]: {
    padding: '60px 40px 40px',
  },
});

const BoxDate = styled('div')(
  {
    fontSize: 14,
    marginBottom: '10px',
  },
  props => ({
    color: props.theme.primaryColor,
  }),
);

const BoxHeadline = styled('h3')(
  {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
    marginBottom: '20px',
    [media(0, 'sm')]: {
      maxWidth: 450,
    },
    [media('md')]: {
      marginBottom: '40px',
    },
  },
  props => ({
    color: props.theme.primaryColor,
  }),
);

const BoxText = styled('p')(
  {
    lineHeight: 1.7,
    overflow: 'hidden',
    marginBottom: '40px',
    maxHeight: 140,
    fontSize: '14px',

    [media('sm')]: {
      flex: 1,
      overflow: 'hidden',
      maxHeight: 'none',
    },
  },
  props => ({
    color: props.theme.textColor,
  }),
);

const BoxReadMoreLink = styled(ReadMoreLink)({
  display: 'block',
  marginTop: 'auto',
});

export default function TextBox({ date, title, text, link, textHeight }) {
  // console.log({ link });
  return (
    <Box>
      {date && (
        <BoxDate>
          <Moment format="DD.MM.YYYY" unix>
            {date}
          </Moment>
        </BoxDate>
      )}
      {title && (
        <Link to={link}>
          <BoxHeadline>{parseAsText(title)}</BoxHeadline>
        </Link>
      )}
      {text && (
        <BoxText>{parseAsText(`${text.substring(0, 800)} ...`)}</BoxText>
      )}
      <BoxReadMoreLink to={link}>Weiterlesen</BoxReadMoreLink>
    </Box>
  );
}

TextBox.propTypes = {
  date: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

TextBox.defaultProps = {
  date: null,
  title: '',
};
