import React from 'react';
import PropTypes from 'prop-types';

import { SubmitButtonStyled } from './SubmitButtonStyled';

export default function SubmitButton({
  children,
  type,
  onClick,
  disabled,
  className,
}) {
  return (
    <SubmitButtonStyled
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </SubmitButtonStyled>
  );
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

SubmitButton.defaultProps = {
  disabled: false,
  type: 'submit',
};
