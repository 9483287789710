import React from 'react';
import PropTypes from 'prop-types';

import { parseAsText, parseAsContent } from 'utils/parser';

import RedactorText from 'components/RedactorText';

export default function Redactor({ content, parseType }) {
  switch (parseType) {
    case 'text':
      return <RedactorText>{parseAsText(content)}</RedactorText>;
    case 'content':
      return parseAsContent(content);
    default:
      return null;
  }
}

Redactor.propTypes = {
  content: PropTypes.string,
  parseType: PropTypes.string,
};

Redactor.defaultProps = {
  content: '',
  parseType: 'content',
};
