import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Link from 'components/Link';

export const SectionLogosStyled = styled('section')({
  overflow: 'hidden',
  background: 'white',
  padding: '30px 0',
});

export const SectionLogosWrapperStyled = styled('div')({
  textAlign: 'center',
  padding: '30px 20px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',

  [media('sm')]: {
    padding: '25px 140px 25px',
  },

  [media('lg')]: {
    padding: '25px 140px 25px',
  },
});

export const SectionLogosLogoWrapStyled = styled('div')({
  boxSizing: 'border-box',
  display: 'block',
  width: 'calc((100% / 2) - 20px)',
  textAlign: 'center',
  verticalAlign: 'middle',
  margin: '10px',

  [media('xs')]: {
    width: 'calc((100% / 3) - 20px)',
    margin: '10px',
  },

  [media('sm')]: {
    width: 'calc((100% / 4) - 20px)',
    margin: '10px',
  },
});

export const SectionLogosLogoStyled = styled('img')({
  textAlign: 'center',
  width: '100%',
  height: 'auto',
  verticalAlign: 'middle',
});

export const LinkStyled = styled(Link)({
  display: 'block',
});
