import { useCallback, useReducer } from 'react';

const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const LOADING = 'LOADING';

export function useCraftFormSubmit() {
  const [submitState, dispatch] = useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case SUCCESS: {
          return { ...state, success: true, errors: null, loading: false };
        }
        case ERROR: {
          return {
            ...state,
            success: false,
            errors: payload?.errors || true,
            loading: false,
          };
        }
        case LOADING: {
          return { ...state, loading: true };
        }
        default: {
          throw new Error(
            `Unknown CraftContactFormSubmit action type "${type}"`,
          );
        }
      }
    },
    { success: false, errors: null, loading: false },
  );

  const handleSubmit = useCallback(
    async formData => {
      dispatch({ type: LOADING });
      try {
        const res = await fetch(process.env.GATSBY_CONTACT_FORM_SEND_ENDPOINT, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const { success, errors } = await res.json();

        if (success) {
          dispatch({ type: SUCCESS });
        } else {
          dispatch({ type: ERROR, payload: { errors } });
        }
      } catch (o_O) {
        dispatch({ type: ERROR });
      }
    },
    [dispatch],
  );

  return [handleSubmit, submitState];
}
