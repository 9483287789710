import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Link from 'components/Link';

import { css, styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Headline from 'components/Headline';
import Container from 'components/Container';
import Section from 'components/Section';
import Text from 'components/Text';
import { buttonTertiary } from 'components/Button/Button';
import { bgGradientOverlayBlack } from 'components/Gradients';

const heroSection = css({
  backgroundSize: 'cover',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  zIndex: '0',
  [media('xs')]: {
    minHeight: 640,
  },
});

const HeroContent = styled('div')({
  maxWidth: 780,
  textAlign: 'center',
  color: 'white',
  padding: '30px 0',
  margin: '0 auto',
  a: {
    marginTop: 40,
  },
});

export default class Hero extends Component {
  render() {
    const {
      headline,
      text,
      backgroundImage,
      buttonText,
      buttonLink,
    } = this?.props?.pageBuilder;
    const bgImage = backgroundImage[0]
      ? `url(' ${backgroundImage[0].hero} ')`
      : 'none';

    const styles = {
      backgroundImage: `${bgImage}`,
      backgroundPosition: '50% 0%',
    };

    return (
      <Section
        id={this?.props?.id}
        style={styles}
        className={`heroSection ${bgGradientOverlayBlack} ${heroSection}`}
      >
        <Container>
          <HeroContent>
            {headline && (
              <Headline tag="h3" brush={{ color: 'white' }}>
                {headline}
              </Headline>
            )}
            {text && <Text tag="div">{text}</Text>}
            {buttonLink[0] && (
              <Link
                to={buttonLink[0].uri}
                className={`btn-link ${buttonTertiary}`}
              >
                {buttonText}
              </Link>
            )}
          </HeroContent>
        </Container>
      </Section>
    );
  }
}

export const query = graphql`
  fragment PageBuilderHeroQuery on Craft_PageBuilderHero {
    headline
    text
    buttonText
    buttonLink {
      uri
    }
    backgroundImage {
      url
      hero: url(transform: transformHero)
    }
  }
`;
