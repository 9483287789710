import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Link from 'components/Link';

export const TileStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(42, 106, 250, .1)',
  color: 'rgba(0,0,0,.7)',
  padding: '10px 20px',

  [media('sm')]: {
    padding: '20px 40px',
  },
});

export const DateStyled = styled('span')({
  display: 'block',
  marginRight: 10,

  [media('sm')]: {
    display: 'inline-block',
  },
});

export const TitleStyled = styled('h4')(
  {
    display: 'inline',
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
    marginBottom: 0,

    [media(0, 'sm')]: {
      maxWidth: 450,
    },
  },
  props => ({
    color: props.theme.primaryColor,
  }),
);

export const IntroStyled = styled('p')({
  display: 'block',
  margin: 0,
});

export const DownloadStyled = styled('div')(
  {
    display: 'inline-flex',
    marginLeft: 20,
    padding: 10,

    [media('sm')]: {
      marginLeft: 40,
    },
  },
  props => ({
    opacity: props.available ? '1' : 0.2,
  }),
);

export const DownloadLinkStyled = styled(Link)({
  display: 'inline-flex',
});
