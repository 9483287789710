import React from 'react';
import PropTypes from 'prop-types';

import { styled } from 'utils/emotion';
import { withTheme } from 'emotion-theming';
import media from 'utils/mediaqueries';

import Headline from 'components/Headline';
import TextBox from 'components/TextBox';
import Redactor from 'components/Redactor';

const Row = styled('div')(props => ({
  [media('md')]: {
    display: 'flex',
    height: '100%',
    paddingTop: props.paddingTop,
    flexBasis: props.width,
    width: props.width,
    maxWidth: props.width,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
  },
}));

const Column = styled('div')(props => ({
  [media('md')]: {
    flexBasis: props.width,
    maxWidth: props.width,
    justifyContent: props.justifyContent,
  },
}));

const BoxesRow = styled(Row)(props => ({
  [media('sm')]: {
    display: 'flex',
    height: '100%',
    flexBasis: props.width,
    width: props.width,
    maxWidth: props.width,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
  },
}));

const BoxesColumn = styled(Column)(props => ({
  [media('sm')]: {
    flexBasis: props.width,
    maxWidth: props.width,
    justifyContent: props.justifyContent,
  },
}));

const TextWrap = styled('div')({
  textAlign: 'center',

  '.text': {
    maxWidth: '100%',
    marginBottom: 40,
  },

  [media('md')]: {
    paddingTop: 570,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    height: '100%',
    textAlign: 'left',

    '.text': {
      display: 'block',
      marginBottom: 0,
    },
  },
});

function TeaserTextBoxSection({ headline, text, entries, theme }) {
  return (
    <Row width="100%" justifyContent="space-between" alignItems="stretch">
      <Column width="40%" justifyContent="flex-end">
        <TextWrap>
          {headline && (
            <Headline
              className="headline"
              tag="h2"
              brush={{ color: theme.primaryColor }}
            >
              {headline}
            </Headline>
          )}
          {text && (
            <div className="text">
              <Redactor content={text} />
            </div>
          )}
        </TextWrap>
      </Column>
      <Column width="55%">
        <BoxesRow width="100%" justifyContent="space-between" paddingTop="300">
          {entries.map(entry => (
            <BoxesColumn width="47.5%" key={entry.id}>
              <TextBox
                date={entry.postDate}
                title={entry.title}
                text={entry.newsContent?.content}
                link={entry.fullUri}
              />
            </BoxesColumn>
          ))}
        </BoxesRow>
      </Column>
    </Row>
  );
}

TeaserTextBoxSection.propTypes = {
  headline: PropTypes.any.isRequired,
  entries: PropTypes.array.isRequired,
};

TeaserTextBoxSection.defaultProps = {};

export default withTheme(TeaserTextBoxSection);
