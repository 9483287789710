import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

const LocationsListWrapper = styled('div')({
  marginTop: 50,
  textAlign: 'left',
  minHeight: 'initial',

  [media('sm')]: {
    minHeight: 700,
  },

  [media('md')]: {
    minHeight: 650,
  },
});

const LocationsList = styled('ul')({
  marginTop: 50,
  columnGap: 30,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.1,

  [media('sm')]: {
    columnCount: 2,
    columnWidth: 'auto',
  },

  [media('md')]: {
    columnCount: 2,
    columnWidth: 'auto',
  },

  [media('lg')]: {
    columnCount: 3,
    fontSize: 16,
    columnGap: 60,
  },
});

const LocationsListItem = styled('li')({
  textAlign: 'left',
  margin: '0 0 15px',
  listStyle: 'none',
  display: 'inline-block',
  width: '100%',
});

const LocationListItemInner = styled('span')({}, props => ({
  cursor: 'pointer',
  lineHeight: '1.5',
  borderBottom: props.active ? '2px solid white' : 0,

  '&:hover': {
    borderBottom: props.active
      ? '2px solid white'
      : '2px solid rgba(255,255,255,.5)',
  },
}));

/**
 * Toggle button.
 */
const Toggle = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: 5,
  overflow: 'hidden',
  border: '1px solid currentColor',
  marginBottom: 36,
  maxWidth: 320,

  [media('md')]: {
    maxWidth: 380,
  },
});

const ToggleOption = styled('span')(
  {
    flex: 1,
    display: 'block',
    cursor: 'pointer',
    fontWeight: '600',
    borderBottom: 0,
    padding: '20px',
    color: '#ffffff',
  },
  props =>
    props.active && {
      color: props.theme.primaryColor,
      backgroundColor: '#ffffff',
    },
);

/**
 * List.
 */
const List = props => {
  const {
    items,
    isTypeOffices,
    isTypeCourts,
    activeLocation,
    onListItemClick,
    onToggleOptionOfficesClick,
    onToggleOptionCourtsClick,
  } = props;

  return (
    <LocationsListWrapper>
      <Toggle>
        <ToggleOption
          name="Gerichtsstandorte"
          active={isTypeCourts}
          onClick={onToggleOptionCourtsClick}
        >
          Gerichtsstandorte
        </ToggleOption>
        <ToggleOption
          name="Büros"
          active={isTypeOffices}
          onClick={onToggleOptionOfficesClick}
        >
          Büros
        </ToggleOption>
      </Toggle>

      <LocationsList>
        {items.map(location => {
          // Determine is item is active.
          const isActive = activeLocation
            ? activeLocation.id === location.id
            : false;

          return (
            <LocationsListItem key={`location-${location.id}`}>
              <LocationListItemInner
                onClick={() => onListItemClick(location)}
                active={isActive}
              >
                {location.title}
              </LocationListItemInner>
            </LocationsListItem>
          );
        })}
      </LocationsList>
    </LocationsListWrapper>
  );
};

List.propTypes = {
  items: PropTypes.array.isRequired,
  isTypeOffices: PropTypes.bool.isRequired,
  isTypeCourts: PropTypes.bool.isRequired,
  activeLocation: PropTypes.object,
  onListItemClick: PropTypes.func.isRequired,
  onToggleOptionOfficesClick: PropTypes.func.isRequired,
  onToggleOptionCourtsClick: PropTypes.func.isRequired,
};

List.defaultProps = {
  activeLocation: null,
};

export default List;
