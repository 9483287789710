import React from 'react';
import { graphql } from 'gatsby';

import { parseAsContent } from 'utils/parser';

import themeDefault from 'components/Layout/themeDefault';
import { bgGradientBlue } from 'components/Gradients';
import Headline from 'components/Headline';
import Container from 'components/Container';
import ContactForm from 'components/ContactForm';
import IconMail from 'components/Svg/IconMail';
import IconMarker from 'components/Svg/IconMarker';
import IconTel from 'components/Svg/IconTel';

import {
  SectionContactStyled,
  SectionContactWrapperStyled,
  HeadlineWrapperStyled,
  RowStyled,
  ColumnStyled,
  InfoStyled,
  InfoLabelStyled,
  InfoValueStyled,
  InfoIconStyled,
} from './SectionContactStyled';

export default function SectionContact({ pageBuilder }) {
  const {
    contactHeadline,
    contactNameLabel,
    contactEmailLabel,
    contactPhoneLabel,
    contactTopicLabel,
    contactMessageLabel,
    contactPrivacyLabel,
    contactButtonText,
    contactInfos,
  } = pageBuilder;

  return (
    <SectionContactStyled id="kontakt" className={bgGradientBlue}>
      <Container>
        <SectionContactWrapperStyled>
          {contactHeadline && (
            <HeadlineWrapperStyled>
              <Headline tag="h3" brush={{ color: themeDefault.primaryColor }}>
                {contactHeadline}
              </Headline>
            </HeadlineWrapperStyled>
          )}
          <RowStyled>
            <ColumnStyled>
              <ContactForm
                formEmailLabel={contactEmailLabel}
                formNameLabel={contactNameLabel}
                formPhoneLabel={contactPhoneLabel}
                formTopicLabel={contactTopicLabel}
                formMessageLabel={contactMessageLabel}
                formPrivacyText={contactPrivacyLabel?.content}
                formSuccessText="Kontaktanfrage erfolgreich versendet."
                formErrorText="Es ist ein Fehler aufgetreten. Überprüfen Sie Ihre Eingaben und versuchen Sie es erneut."
                formSubmitButtonText={contactButtonText}
                formSubmitButtonTextSending="Sendet..."
              />
            </ColumnStyled>
            <ColumnStyled>
              {contactInfos.length > 0 &&
                contactInfos.map(info => {
                  let icon = null;

                  switch (info.contactInfoIcon) {
                    case 'iconMail':
                      icon = <IconMail />;
                      break;
                    case 'iconMarker':
                      icon = <IconMarker />;
                      break;
                    case 'iconTel':
                      icon = <IconTel />;
                      break;
                    default:
                      icon = null;
                      break;
                  }

                  return (
                    <InfoStyled key={info.id}>
                      <InfoLabelStyled>{info.contactInfoLabel}</InfoLabelStyled>
                      <InfoValueStyled>
                        {icon && <InfoIconStyled>{icon}</InfoIconStyled>}{' '}
                        {parseAsContent(info.contactInfoValue?.content)}
                      </InfoValueStyled>
                    </InfoStyled>
                  );
                })}
            </ColumnStyled>
          </RowStyled>
        </SectionContactWrapperStyled>
      </Container>
    </SectionContactStyled>
  );
}

export const query = graphql`
  fragment PageBuilderSectionContactQuery on Craft_PageBuilderSectionContact {
    id
    contactHeadline
    contactNameLabel
    contactEmailLabel
    contactPhoneLabel
    contactTopicLabel
    contactMessageLabel
    contactPrivacyLabel {
      content
    }
    contactButtonText
    contactInfos {
      id
      contactInfoIcon
      contactInfoLabel
      contactInfoValue {
        content
      }
    }
  }
`;
