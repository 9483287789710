import React, { Component } from 'react';
import './SliderFlickity.css';

export default class FlickitySlider extends Component {
  state = { Flickity: null };

  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      const Flickity = require('react-flickity-component');
      this.state.Flickity = Flickity;
    }
  }

  render() {
    const { Flickity } = this.state;

    return (
      <>
        {Flickity && <Flickity {...this.props}>{this.props.children}</Flickity>}
      </>
    );
  }
}
