import React from 'react';

import { parseAsContent } from 'utils/parser';

import {
  FormFieldWrapperStyled,
  FormFieldTextStyled,
  FormFieldCheckboxStyled,
  FormFieldTextareaStyled,
  FormFieldCheckboxLabelStyled,
  FormFieldLabelStyled,
  MessageErrorStyled,
} from './FormFieldStyled';

function FormField({
  type,
  name,
  label,
  required,
  initialValue,
  onChange,
  messageError,
  ...rest
}) {
  let inputElement;
  switch (type) {
    case 'text':
    case 'tel':
    case 'email':
    case 'number':
      inputElement = (
        <FormFieldTextStyled
          type={type}
          {...rest}
          name={name}
          required={required}
          onChange={onChange}
        />
      );
      break;
    case 'checkbox':
      inputElement = (
        <FormFieldCheckboxStyled
          type={type}
          {...rest}
          name={name}
          required={required}
          onChange={onChange}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <FormFieldTextareaStyled
          {...rest}
          name={name}
          required={required}
          onChange={onChange}
        />
      );
      break;
    default:
      throw new Error(`Unknown FormField type: "${type}"`);
  }

  return (
    <FormFieldWrapperStyled>
      {label && type !== 'checkbox' && (
        <FormFieldLabelStyled htmlFor={rest?.id}>
          {parseAsContent(label, { onlyBasicTags: true })}{' '}
          {required ? null : ' (optional)'}
        </FormFieldLabelStyled>
      )}
      {inputElement}
      {label && type === 'checkbox' && (
        <FormFieldCheckboxLabelStyled htmlFor={rest?.id}>
          {parseAsContent(label, { onlyBasicTags: true })}{' '}
          {required ? null : ' (optional)'}
        </FormFieldCheckboxLabelStyled>
      )}
      {messageError && <MessageErrorStyled>{messageError}</MessageErrorStyled>}
    </FormFieldWrapperStyled>
  );
}

export default FormField;
