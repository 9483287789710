import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Image from 'components/Image';
import Text from 'components/Text';

export default class ImageText extends Component {
  render() {
    const { image, text } = this.props.pageBuilder;

    return (
      <section id={this.props.id}>
        <Container>
          {image[0] && <Image src={image[0].url} />}
          {text && <Text tag="div">{text}</Text>}
        </Container>
      </section>
    );
  }
}

export const query = graphql`
  fragment PageBuilderImageTextQuery on Craft_PageBuilderImageText {
    text
    image {
      url
    }
  }
`;
