import { styled } from 'utils/emotion';

export const GeneralMessageStyled = styled('div')(
  {
    fontWeight: '600',
    marginBottom: 12,
  },
  ({ type, theme }) =>
    type === 'error' && {
      color: theme.colorError,
    },
  ({ type, theme }) =>
    type === 'success' && {
      color: theme.primaryColor,
    },
);
