import React from 'react';
import { styled } from 'utils/emotion';

const SvgStyled = styled('svg')(
  {
    width: '1.2em',
    height: '1.2em',
  },
  props => ({
    fill: props.color ? props.color : 'currentColor',
  }),
);

const IconCross = props => {
  const { className } = props;

  return (
    <SvgStyled
      viewBox="0 0 20 16"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M8 6.1L2.3.4A1.344 1.344 0 1 0 .4 2.3L6.1 8 .4 13.7a1.344 1.344 0 1 0 1.9 1.9L8 9.9l5.7 5.7a1.344 1.344 0 1 0 1.9-1.9L9.9 8l5.7-5.7A1.344 1.344 0 1 0 13.7.4z"
      />
    </SvgStyled>
  );
};

export default IconCross;
