import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

export const SubmitButtonStyled = styled('button')(
  {
    position: 'relative',
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    border: 0,
    cursor: 'pointer',
    fontSize: '16px',
    marginBottom: '1rem',
    textAlign: 'center',
    padding: '14px 40px',
    color: '#ffffff',
    fontWeight: '600',

    '&:hover, &:focus, &:active': {
      color: '#ffffff',
      outline: 0,
      WebkitTapHighlightColor: 'transparent',
    },
  },
  props => ({
    margin: props.margin,
    borderRadius: props.theme.borderRadius,
    backgroundColor: props.theme.primaryColor,

    '&:hover, &:focus, &:active': {
      backgroundColor: props.theme.primaryColorDark,
    },
  }),
  props =>
    props.disabled && {
      opacity: 0.3,
      pointerEvents: 'none',
    },
);
