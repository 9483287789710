import React from 'react';
import PropTypes from 'prop-types';

import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import { toPx } from 'utils/strings';

import NullWrap from 'components/NullWrap';

const StyledWrapGrid = styled('div')(
  {
    position: 'relative',
  },
  props => ({
    display: props.display,
    margin: props.margin,
    padding: props.padding,
    height: props.height,
    flex: props.flex,
    width: `calc(${toPx(props.theme.wrapSizes.xxs)} - ${toPx(props.gutter)})`,
    [media('xs')]: {
      width: `calc(${toPx(props.theme.wrapSizes.xs)} - ${toPx(props.gutter)})`,
    },
    [media('sm')]: {
      width: `calc(${toPx(props.theme.wrapSizes.sm)} - ${toPx(props.gutter)})`,
    },
    [media('md')]: {
      width: `calc(${toPx(props.theme.wrapSizes.md)} - ${toPx(props.gutter)})`,
    },
    [media('lg')]: {
      width: `calc(${toPx(props.theme.wrapSizes.lg)} - ${toPx(props.gutter)})`,
    },
    [media('xl')]: {
      width: `calc(${toPx(props.theme.wrapSizes.xl)} - ${toPx(props.gutter)})`,
    },
  }),
);

export default function WrapGrid({
  children,
  margin,
  padding,
  height,
  isNested,
  display,
  flex,
  gutter,
}) {
  const WrapComponent = isNested ? NullWrap : StyledWrapGrid;
  return (
    <WrapComponent
      gutter={gutter}
      margin={margin}
      padding={padding}
      height={height}
      display={display}
      flex={flex}
      isGlobalGridWrapper={!isNested}
    >
      {children}
    </WrapComponent>
  );
}

WrapGrid.propTypes = {
  margin: PropTypes.string,
};

WrapGrid.defaultProps = {
  margin: '0 auto',
};
