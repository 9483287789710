import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

export const SectionContactStyled = styled('section')({
  padding: '20px',

  [media('md')]: {
    padding: '70px 40px',
  },

  [media('lg')]: {
    padding: '100px 0',
  },
});

export const SectionContactWrapperStyled = styled('div')(
  {
    background: 'white',
    boxShadow: '0 10px 10px rgba(0,0,0,.15)',
    padding: '30px 25px 30px',

    [media('md')]: {
      padding: '80px 110px',
    },
  },
  ({ theme }) => ({
    borderRadius: theme.borderRadius,
  }),
);

export const HeadlineWrapperStyled = styled('div')({
  textAlign: 'center',

  [media('md')]: {
    textAlign: 'left',
  },
});

export const RowStyled = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

export const ColumnStyled = styled('div')({
  width: '100%',
  flexBasis: '100%',
  marginBottom: '20px',

  [media('sm')]: {
    width: 'calc(50% - 20px)',
    flexBasis: 'calc(50% - 20px)',
    marginBottom: 0,
  },

  [media('md')]: {
    width: 'calc(50% - 50px)',
    flexBasis: 'calc(50% - 50px)',
  },
});

/**
 * Info.
 */
export const InfoStyled = styled('div')({
  marginBottom: '30px',
});

export const InfoLabelStyled = styled('div')({
  display: 'block',
  fontSize: '.7em',
  marginBottom: '5px',
});

export const InfoValueStyled = styled('div')({
  display: 'flex',
  fontSize: '1em',

  p: {
    margin: 0,
  },

  'a, a:hover': {
    color: 'inherit',
    fontWeight: 'inherit',
  },
});

export const InfoIconStyled = styled('span')({
  flex: 'none',
  display: 'inline-flex',
  fontSize: '.7em',
  marginRight: '20px',
  marginTop: '.6em',
});
