import React from 'react';
import PropTypes from 'prop-types';

import { styled, keyframes } from 'utils/emotion';
import media from 'utils/mediaqueries';

import { BLUE_TRANS_GRADIENT } from 'components/Gradients';
import IconMail from 'components/Svg/IconMail';
import IconTel from 'components/Svg/IconTel';
import IconCross from 'components/Svg/IconCross';

const slideIn = keyframes`
  0% {
    transform: translateX(120%);
  }

  100% {
    transform: translateX(0);
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(120%);
  }

  100% {
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const CardWrapper = styled('div')({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  padding: 20,
  zIndex: '1',
  display: 'flex',
  alignItems: 'center',

  [media('sm')]: {
    position: 'absolute',
    bottom: 'auto',
    padding: 0,
    left: 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 60,
    height: 'auto',
    maxWidth: '100%',
    width: 260,
  },

  [media('md')]: {
    width: 360,
  },

  [media('lg')]: {
    width: 420,
  },
});

// Making the card appear as overlay so the user is guided better.
const CardWrapperBg = styled('div')({
  position: 'absolute',
  content: '""',
  display: 'block',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0,
  background: BLUE_TRANS_GRADIENT,
  animation: `${fadeIn} .3s linear forwards`,

  [media('sm')]: {
    display: 'none',
  },
});

const CardStyled = styled('div')({
  position: 'relative',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  borderRadius: 5,
  color: '#000',
  width: '100%',
  overflow: 'hidden',
  boxShadow: '0 3px 6px rgba(0,0,0,.15)',
  lineHeight: '1.5',
  fontSize: 13,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'column',
  transform: 'translateY(100%)',
  animation: `${slideUp} .5s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards`,

  [media('sm')]: {
    transform: 'translateX(120%)',
    animation: `${slideIn} .5s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards`,
  },

  [media('md')]: {
    fontSize: 14,
    flexDirection: 'row',
  },
});

// Left column
const WrapperStyled = styled('div')({
  position: 'relative',
  boxSizing: 'border-box',
  padding: 24,
  flex: 1,
});

const TitleStyled = styled('p')({
  fontSize: '1.1em',
  fontWeight: '600',
  lineHeight: '1.5',
  margin: '0 0 14px',
  paddingRight: 45,
});

const ParagraphStyled = styled('p')({
  margin: '0 0 14px',

  '&:last-child': {
    margin: '0',
  },
});

const LinkStyled = styled('a')({
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  color: 'currentColor',

  '&:hover': {
    color: 'currentColor',
  },
});

const CloseButtonStyled = styled('button')({
  color: '#000',
  position: 'absolute',
  padding: '25px 15px',
  top: 0,
  right: 0,
  cursor: 'pointer',

  ':focus': {
    outline: 0,
    opacity: '.5',
  },
});

// Right column
const ActionsStyled = styled('div')(
  {
    flex: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: '100%',
    height: 75,

    [media('md')]: {
      flexDirection: 'column',
      width: 100,
      height: 'auto',
    },
  },
  props => ({
    backgroundColor: props.theme.primaryColor,
  }),
);

const ActionWrapperStyled = styled('div')({
  flex: 1,
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
});

const DividerStyled = styled('hr')({
  flex: 'none',
  display: 'block',
  width: 1,
  height: '100%',
  border: 0,
  backgroundColor: 'rgba(255,255,255,.7)',

  [media('md')]: {
    height: 1,
    width: '100%',
  },
});

const ButtonStyled = styled('a')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: '100%',
    width: 45,
    height: 45,
    boxShadow: '0 3px 6px rgba(0,0,0,.15)',
    transition: 'transform .1s ease-in-out',
    transformOrigin: '50% 50%',

    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  props => ({
    color: props.theme.primaryColor,
  }),
);

const Card = props => {
  const {
    title,
    locationRelated,
    locationType,
    locationAddress,
    locationZip,
    locationCity,
    locationPhone,
    locationFax,
    locationEmail,
    onCloseButtonClick,
  } = props;

  // Ain't pretty, but works.
  const type = locationType[0].locationTypeId;

  return (
    <CardWrapper>
      <CardWrapperBg onClick={onCloseButtonClick} />
      <CardStyled>
        <WrapperStyled>
          <TitleStyled>{title}</TitleStyled>

          {type === 'court' &&
            locationRelated.length > 0 && (
              <ParagraphStyled>
                Zuständiges Büro
                <br />
                {locationRelated.map(related => related.title).join(', ')}
              </ParagraphStyled>
            )}

          <ParagraphStyled>
            {locationAddress}
            <br />
            {locationZip} {locationCity}
          </ParagraphStyled>

          <ParagraphStyled>
            {locationPhone && (
              <>
                Telefon:{' '}
                <LinkStyled href={`tel:${locationPhone}`}>
                  {locationPhone}
                </LinkStyled>
                <br />
              </>
            )}
            {locationFax && (
              <>
                Telefax: {locationFax}
                <br />
              </>
            )}
            {locationEmail && (
              <LinkStyled href={`mailto:${locationEmail}`}>
                {locationEmail}
              </LinkStyled>
            )}
          </ParagraphStyled>

          {type === 'office' &&
            locationRelated.length > 0 && (
              <ParagraphStyled>
                <strong>
                  Gerichtsstandort{' '}
                  {locationRelated.map(related => related.title).join(', ')}
                </strong>
              </ParagraphStyled>
            )}

          <CloseButtonStyled onClick={onCloseButtonClick}>
            <IconCross />
          </CloseButtonStyled>
        </WrapperStyled>

        <ActionsStyled>
          {locationPhone && (
            <ActionWrapperStyled>
              <ButtonStyled
                href={`tel:${locationPhone}`}
                title={`Anruf an:${locationPhone}`}
              >
                <IconTel />
              </ButtonStyled>
            </ActionWrapperStyled>
          )}
          <DividerStyled />
          {locationEmail && (
            <ActionWrapperStyled>
              <ButtonStyled
                href={`mailto:${locationEmail}`}
                title={`E-Mail an: ${locationEmail}`}
              >
                <IconMail />
              </ButtonStyled>
            </ActionWrapperStyled>
          )}
        </ActionsStyled>
      </CardStyled>
    </CardWrapper>
  );
};

Card.propTypes = {
  locationType: PropTypes.array,
  locationRelated: PropTypes.array,
  locationAddress: PropTypes.string.isRequired,
  locationZip: PropTypes.string.isRequired,
  locationCity: PropTypes.string.isRequired,
  locationPhone: PropTypes.string.isRequired,
  locationFax: PropTypes.string.isRequired,
  locationEmail: PropTypes.string.isRequired,
};

Card.defaultProps = {
  locationRelated: [],
  locationType: [],
};

export default Card;
