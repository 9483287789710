import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Container from 'components/Container';
import { bgGradientBlue } from 'components/Gradients';

const MembershipsSection = styled('section')({
  overflow: 'hidden',
  background: 'white',
});

const MembershipsWrapper = styled('div')({
  textAlign: 'center',
  padding: '60px 0 30px',

  [media('sm')]: {
    padding: '80px 40px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    overflowX: 'auto',
  },

  [media('lg')]: {
    padding: '80px 0',
  },
});

const MembershipLogoWrap = styled('div')({
  width: '100%',
  textAlign: 'center',
  verticalAlign: 'middle',
  marginBottom: 50,

  [media('xxs')]: {
    width: '50%',
    display: 'inline-block',
  },

  [media(580)]: {
    width: '33.333%',
    display: 'inline-block',
  },

  [media('sm')]: {
    marginBottom: 0,
    width: 'auto',
    '&:last-child img': {
      marginRight: 0,
    },
  },
});

const MembershipLogo = styled('img')({
  textAlign: 'center',
  maxHeight: 60,
  maxWidth: 180,
  width: 'auto',

  [media('xxs')]: {
    maxWidth: 160,
  },

  [media(580)]: {
    maxHeight: 80,
    maxWidth: 160,
  },

  [media('sm')]: {
    margin: '0 80px 0 0',
  },
});

class Memberships extends Component {
  render() {
    const {
      data: {
        craft: { globals },
      },
    } = this.props;

    const memberships = globals.generalContent.memberships;

    return (
      <MembershipsSection>
        <Container>
          <MembershipsWrapper>
            {memberships &&
              memberships.map((el, index) => (
                <MembershipLogoWrap key={el.id}>
                  {el.membershipLogo && (
                    <MembershipLogo
                      src={el.membershipLogo[0].url}
                      alt={el.membershipName}
                    />
                  )}
                </MembershipLogoWrap>
              ))}
          </MembershipsWrapper>
        </Container>
      </MembershipsSection>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query MembershipsQuery {
        craft {
          globals {
            generalContent {
              memberships {
                id
                membershipName
                membershipLogo {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Memberships data={data} {...props} />}
  />
);
