import moment from 'moment';

/**
 * Group items by year.
 *
 * @param {*} items
 * @param {*} itemDateField
 */
export function groupItemsByYear(items, itemDateField) {
  const groupingMethod = {
    getGroupTitle: d => moment(d).format('YYYY'),
    getGroupKey: d => moment(d).format('YYYY'),
  };

  const dateGroups = !items
    ? {}
    : items.reduce((groupedItems, item, index) => {
        const itemDate = moment.unix(item[itemDateField]);
        const groupKey = groupingMethod.getGroupKey(itemDate);

        if (!groupedItems.hasOwnProperty(groupKey)) {
          // create group from scratch
          groupedItems[groupKey] = {
            id: groupKey,
            title: groupKey,
            itemCount: 0,
            items: [],
          };
        }

        // push items
        groupedItems[groupKey].items.push(item);

        // add item count to group
        groupedItems[groupKey].itemCount++;

        return groupedItems;
      }, {});

  return dateGroups;
}
