import React from 'react';
import { styled } from 'utils/emotion';
import Link from 'components/Link';

const ReadMoreLinkStyled = styled(Link)({
  position: 'relative',
  width: '100%',
  fontWeight: '400',
  '&:before': {
    content: "''",
    background:
      'linear-gradient(rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 85%)',
    height: '100px',
    position: 'absolute',
    bottom: '100%',
    width: '100%',
    left: '0',
    right: '0',
  },
});

export default function ReadMoreLink(props) {
  return <ReadMoreLinkStyled {...props}>{props.children}</ReadMoreLinkStyled>;
}
