import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';

import {
  SectionLogosStyled,
  SectionLogosWrapperStyled,
  SectionLogosLogoWrapStyled,
  SectionLogosLogoStyled,
  LinkStyled,
} from './SectionLogosStyled';

export default function SectionLogos({ pageBuilder }) {
  const logos = pageBuilder?.logos;

  if (!logos && logos.length <= 0) {
    return null;
  }

  return (
    <SectionLogosStyled>
      <Container>
        <SectionLogosWrapperStyled>
          {logos.map(el => {
            let content = null;
            const imageUrl = el.logoImage?.[0]?.url;

            if (el.logoUrl && imageUrl) {
              // Link image with url when both are available
              content = (
                <LinkStyled to={el.logoUrl} target="_blank">
                  <SectionLogosLogoStyled src={imageUrl} alt={el.logoName} />
                </LinkStyled>
              );
            } else if (el.logoUrl && !imageUrl) {
              // Link without image
              content = (
                <LinkStyled to={el.logoUrl} target="_blank">
                  {el.logoName}
                </LinkStyled>
              );
            } else if (!el.logoUrl && el.logoImage) {
              // Only the logo, no link
              content = (
                <SectionLogosLogoStyled src={imageUrl} alt={el.logoName} />
              );
            }

            return (
              <SectionLogosLogoWrapStyled key={el.id}>
                {content}
              </SectionLogosLogoWrapStyled>
            );
          })}
        </SectionLogosWrapperStyled>
      </Container>
    </SectionLogosStyled>
  );
}

export const query = graphql`
  fragment PageBuilderSectionLogosQuery on Craft_PageBuilderSectionLogos {
    id
    logos {
      id
      logoName
      logoUrl
      logoImage {
        url
      }
    }
  }
`;
