import React from 'react';

import { useFormFields } from 'utils/hooks/useFormFields';
import { useCraftFormSubmit } from 'utils/hooks/useCraftFormSubmit';
import { FormField, SubmitButton } from 'components/Form';

import { GeneralMessageStyled } from './ContactFormStyled';

function ContactForm({
  formEmailLabel,
  formNameLabel,
  formPhoneLabel,
  formMessageLabel,
  formTopicLabel,
  formPrivacyText,
  formSuccessText,
  formErrorText,
  formSubmitButtonText,
  formSubmitButtonTextSending,
}) {
  const [formRef, getFormProps, getFieldProps] = useFormFields({
    to: 'info@lieser-rechtsanwaelte.de',
    subject: 'Nachricht vom Kontaktformular auf lieser-rechtsanwaelte.de',
  });

  const [handleSubmit, { loading, success, errors }] = useCraftFormSubmit();

  return (
    <form
      ref={formRef}
      {...getFormProps({
        onSubmit: handleSubmit,
      })}
    >
      <input {...getFieldProps({ name: 'to', type: 'hidden' })} />
      <input {...getFieldProps({ name: 'subject', type: 'hidden' })} />
      <FormField
        placeholder={formNameLabel}
        messageError={errors?.['name']}
        {...getFieldProps({ name: 'name', type: 'text' })}
        required
      />
      <FormField
        placeholder={formEmailLabel}
        messageError={errors?.['email']}
        required
        {...getFieldProps({ name: 'email', type: 'email' })}
      />
      <FormField
        placeholder={formPhoneLabel}
        messageError={errors?.['phone']}
        required
        {...getFieldProps({ name: 'phone', type: 'tel' })}
      />
      <FormField
        placeholder={formTopicLabel}
        messageError={errors?.['topic']}
        {...getFieldProps({ name: 'topic', type: 'text' })}
        required
      />
      <FormField
        placeholder={formMessageLabel}
        messageError={errors?.['message']}
        required
        {...getFieldProps({ name: 'message', type: 'textarea' })}
      />
      <FormField
        label={formPrivacyText}
        messageError={errors?.['gdpr']}
        required
        {...getFieldProps({ name: 'gdpr', type: 'checkbox' })}
      />

      {success ? (
        <GeneralMessageStyled type="success">
          {formSuccessText}
        </GeneralMessageStyled>
      ) : null}

      {errors ? (
        <GeneralMessageStyled type="error">
          {formErrorText}
        </GeneralMessageStyled>
      ) : null}

      <SubmitButton type="submit">
        {loading ? formSubmitButtonTextSending : formSubmitButtonText}
      </SubmitButton>
    </form>
  );
}

export default ContactForm;
