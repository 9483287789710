import React, { Component } from 'react';

import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Container from 'components/Container';
import TextDefault from 'components/Text';

const ProfileContainer = styled(Container)({
  outline: 'none',
  transition: 'all .25s ease',
  [media(860)]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const ProfileContentBox = styled('div')({
  marginTop: 40,
  padding: '0 30px',
  verticalAlign: 'top',
  [media(860)]: {
    padding: '30px 15px 0 20px',
    width: '56%',
    display: 'inline-block',
  },
  [media('md')]: {
    paddingTop: 0,
  },
  [media('xl')]: {
    width: '60%',
  },
});

const ProfileNav = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 50,

  [media(860)]: {
    justifyContent: 'flex-start',
  },
});

const ProfileNavButton = styled('button')(
  {
    color: 'white',
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-block',
    margin: '0 25px',
    outline: 'none',
    '&:hover, &:focus': {
      color: 'white',
    },
    [media(860)]: {
      fontSize: 28,
      paddingBottom: 10,
      margin: '0 60px 0 0',
      fontWeight: '600',
    },
  },
  props => ({
    fontWeight: props.active ? 'bold' : '500',
    opacity: props.active ? '1' : '.5',
    borderBottom: props.active ? '2px solid #ffffff' : '2px solid transparent',
    order: props.active ? '0' : '1',

    [media(860)]: {
      order: 'initial',
      borderWidth: '4px',
    },
  }),
);

const ProfileName = styled('div')({
  fontSize: 20,
  width: '30%',
});

const ProfileContent = styled('div')({
  '.text': {
    fontSize: 16,
    color: 'white',
  },
  [media(860)]: {
    maxHeight: '500px',
    height: '50vh',
    overflow: 'hidden',
    overflowY: 'auto',
    paddingRight: '25px',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 10,
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: 'rgba(255,255,255,.3)',
      WebkitBoxShadow: '0',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: 'rgba(255,255,255,.15)',
    },
  },
});

const TextBlock = styled('div')({
  display: 'block',
  padding: '0 0 20px',
  textAlign: 'left',
});

export default class ProfileSlide extends Component {
  state = {
    isTypeVita: true,
    isTypePublications: false,
  };

  componentDidUpdate = () => {
    this.props.teamSliderResize();
  };

  handleVitaClick = () => {
    this.setState({
      isTypeVita: true,
      isTypePublications: false,
    });
  };

  handlePublicationsClick = () => {
    this.setState({
      isTypeVita: false,
      isTypePublications: true,
    });
  };

  render() {
    const { employee } = this.props;
    const { isTypeVita, isTypePublications } = this.state;

    const content = isTypeVita
      ? employee.employeeVita?.content
      : employee.employeePublications?.content;

    return (
      <ProfileContainer>
        <ProfileContentBox>
          <ProfileNav>
            {employee.employeeVita?.content && (
              <ProfileNavButton
                active={isTypeVita}
                onClick={this.handleVitaClick}
              >
                Vita
              </ProfileNavButton>
            )}
            {employee.employeePublications?.content && (
              <ProfileNavButton
                active={isTypePublications}
                onClick={this.handlePublicationsClick}
              >
                Veröffentlichungen
              </ProfileNavButton>
            )}
          </ProfileNav>
          <ProfileContent>
            <TextDefault tag="div" className="text">
              <TextBlock
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </TextDefault>
          </ProfileContent>
        </ProfileContentBox>
      </ProfileContainer>
    );
  }
}
