import React from 'react';

const IconDownload = ({ width, height, className }) => (
  <svg
    viewBox="0 0 18 22.13"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width || '18px'}
    height={height || '22.12px'}
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path d="M9.27,17.62,2.49,10.85" stroke="#2a6afa" />
    <path d="M8.75,17.62,15,10.85" stroke="#2a6afa" />
    <path d="M9.06,16.84,8.75,0" stroke="#2a6afa" />
    <line y1="21.63" x2="18" y2="21.63" stroke="#2a6afa" />
  </svg>
);

export default IconDownload;
