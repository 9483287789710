import React from 'react';
import { graphql } from 'gatsby';

import { styled } from 'utils/emotion';

import Section from 'components/Section';
import Container from 'components/Container';
import Redactor from 'components/Redactor';
import Button from 'components/Button';
import Grid from 'components/Grid';
import GridItem from 'components/GridItem';

const SectionDownloadsStyled = styled(Section)({
  backgroundColor: 'white',
});

const SectionDownloadsContainerStyled = styled('div')({
  marginLeft: '-12px',
  marginBottom: 20,
});

const ContainerStyled = styled(Container)({
  maxWidth: 800,
  margin: '0 auto',
});

export default function SectionDownloads(props) {
  const { id, pageBuilder } = props;
  const { sectionDownloadsText, sectionDownloadsFiles } = pageBuilder;

  return (
    <SectionDownloadsStyled id={id}>
      <ContainerStyled>
        {sectionDownloadsFiles && (
          <SectionDownloadsContainerStyled>
            <Grid>
              {sectionDownloadsFiles.map(file => (
                <GridItem
                  xxs={1 / 1}
                  sm={1 / 2}
                  key={file?.id}
                  margin="0 0 12px 0"
                >
                  <Button
                    fullSize
                    textAlign="center"
                    to={file?.sectionDownloadsFilesFile?.[0]?.url}
                  >
                    {file?.sectionDownloadsFilesTitle}
                  </Button>
                </GridItem>
              ))}
            </Grid>
          </SectionDownloadsContainerStyled>
        )}
        <Redactor content={sectionDownloadsText?.content} />
      </ContainerStyled>
    </SectionDownloadsStyled>
  );
}

export const query = graphql`
  fragment PageBuilderSectionDownloadsQuery on Craft_PageBuilderSectionDownloads {
    id
    sectionDownloadsText {
      content
    }
    sectionDownloadsFiles {
      id
      sectionDownloadsFilesTitle
      sectionDownloadsFilesFile {
        url
      }
    }
  }
`;
