import React from 'react';
import moment from 'moment';
import { StaticQuery, graphql } from 'gatsby';
import { groupItemsByYear } from 'utils/date';
import Container from 'components/Container';
import Tile from './Tile';
import {
  NewsSectionStyled,
  WrapperStyled,
  HeadlineWrapperStyled,
  HeadlineStyled,
  ImageWrapperStyled,
  ImageStyled,
  NavigationWrapperStyled,
  NavigationStyled,
  NavigationItemStyled,
  ContentStyled,
  NoEntriesStyled,
} from './styles';

class NewsArchive extends React.Component {
  state = {
    entriesGroups: undefined,
    entriesGrouped: undefined,
    activeNavItem: undefined,
  };

  componentDidMount = () => {
    // Prepare data
    const { entries } = this.props;
    const entriesGrouped = this.prepareEntries(entries);
    const entriesGroups = this.prepareGroups(entriesGrouped);

    this.setState({
      entriesGroups,
      entriesGrouped,
      activeNavItem: entriesGroups?.[0] || moment().format('YYYY'), // Set first group as active
    });
  };

  /**
   * Function: prepare entries.
   *
   * - Group items by year.
   */
  prepareEntries = entries => groupItemsByYear(entries, 'postDate');

  /**
   * Function: prepare groups.
   *
   * - Sort groups in descending order.
   */
  prepareGroups = preparedEntries =>
    Object.keys(preparedEntries).sort((a, b) => {
      if (a > b) return -1;
      if (a < b) return 1;

      return 0;
    });

  /**
   * Event: handle navigation item click.
   */
  handleNavigationItemClick = id => {
    this.setState({
      activeNavItem: id,
    });
  };

  render() {
    const { headline, image } = this.props;
    const { activeNavItem, entriesGroups, entriesGrouped } = this.state;

    if (!entriesGrouped) {
      return null;
    }

    return (
      <NewsSectionStyled>
        {image && (
          <ImageWrapperStyled>
            <ImageStyled
              src={image?.[0]?.full}
              alt={headline}
              aspectRatio="1:4"
              position="absolute"
              top="0"
              left="0"
            />
          </ImageWrapperStyled>
        )}
        {activeNavItem && (
          <Container position="relative">
            {headline && (
              <HeadlineWrapperStyled>
                <HeadlineStyled tag="h2" brush={{ color: 'white' }}>
                  {headline}
                </HeadlineStyled>
              </HeadlineWrapperStyled>
            )}
            <WrapperStyled>
              <NavigationWrapperStyled>
                <NavigationStyled>
                  {entriesGroups.map(group => (
                    <NavigationItemStyled
                      key={entriesGrouped[group].id}
                      active={activeNavItem === entriesGrouped[group].id}
                      onClick={() =>
                        this.handleNavigationItemClick(entriesGrouped[group].id)
                      }
                    >
                      {entriesGrouped[group].title}
                    </NavigationItemStyled>
                  ))}
                </NavigationStyled>
              </NavigationWrapperStyled>
              <ContentStyled>
                {entriesGrouped?.[activeNavItem]?.itemCount > 0 ? (
                  entriesGrouped[activeNavItem].items.map(entry => (
                    <Tile key={entry.id} entry={entry} />
                  ))
                ) : (
                  <NoEntriesStyled>
                    Im Jahr {activeNavItem} wurden noch keine Pressemitteilungen
                    veröffentlicht.
                  </NoEntriesStyled>
                )}
              </ContentStyled>
            </WrapperStyled>
          </Container>
        )}
      </NewsSectionStyled>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query QueryNewsArchive {
        craft {
          entries(section: [news], orderBy: "postDate DESC") {
            id
            title
            postDate
            fullUri
            ... on Craft_News {
              newsIntro
              newsDownload {
                id
                url
                title
              }
            }
          }
        }
      }
    `}
    render={data => <NewsArchive entries={data?.craft?.entries} {...props} />}
  />
);
